import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
				email
				mapLink
				address
				instagram
				companyDescription: _rawCompanyDescription(resolveReferences: {maxDepth: 4})
				acknowledgement: _rawAcknowledgement(resolveReferences: {maxDepth: 4})
				noProjectsText: _rawNoProjectsText(resolveReferences: {maxDepth: 4})
				newsletterSignUpTitle
				newsletterSuccessMessage
      }
    }
    `)

	return siteSettings?.sanitySiteSettings
}

export default useSiteSettings
