import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, Input } from '~styles/global'
import Section from '~components/Section'
import { Cross } from '~components/Svg'
import { Link, graphql, useStaticQuery } from 'gatsby'
import resolveLink from '~utils/resolveLink'
import { motion } from 'framer-motion'

const Search = ({ className, onClose }) => {

	const inputRef = useRef()
	const searchRef = useRef()
	const [term, setTerm] = useState()
	const [suggestions, setSuggestions] = useState([])

	const allProjects = useStaticQuery(graphql`
	query {
		projects: allSanityProject {
      nodes {
				title
				_type
				slug {
					current
				}
			}
    }
	}
	`)

	const getScore = (project) => { 
		let score = 0
		const projectName = project?.title?.toLowerCase().split('')
		const word = term?.toLowerCase().split('')

		word.forEach((letter, index) => {
			if (letter === projectName[index]) {
				score += 1
			} else if (letter !== projectName[index]) {
				score -= project.score 
			}
		})

		return score
	}

	const projects = allProjects.projects.nodes

	useEffect(() => {
		const suggestionsArray = []

		function compare(a, b) {
			if (a.score > b.score) {
				return -1
			}
			if (a.score < b.score) {
				return 1
			}
			return 0
		}

		if (term){
			projects.forEach(project => {
				const score = getScore(project)
				if(score > 0){
					suggestionsArray.push({...project, score: score})
				}
			})
			suggestionsArray.sort(compare)
			setSuggestions(suggestionsArray)
		} else {
			setSuggestions([])
		}

	}, [term])

	useEffect(() => {
		if(inputRef.current) inputRef.current?.focus()
	}, [inputRef.current])

	return (
		<Wrap 
			className={className}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			ref={searchRef}
		>
			<Container>
				<Background/>
				<StyledSection>
					<Content className='h4'>
						<Flex>
							<Relative>
								<SearchInput 
									ref={inputRef}
									placeholder='What would you like to find?'
									onChange={e => setTerm(e.target.value)}
								/>
								<SearchButton className='h4'>Search</SearchButton>
						
							</Relative>
							<CloseBlock>
								<Close onClick={() => onClose()}>
									<Cross />
								</Close>
							</CloseBlock>
						</Flex>
					</Content>
				</StyledSection>
				<Results className='h4'>
					<ResultsColumn>
						{suggestions.map((suggestion, index) => (
							<Result key={suggestion?.slug?.current} >
								<Link to={resolveLink(suggestion)}>
									{suggestion.title}
								</Link>
							</Result>
						))}
					</ResultsColumn>
				</Results>
			</Container>
		</Wrap>
	)
}

const Wrap = styled(motion.div)`
	position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
	color: ${props => props.home ? 'var(--white)' : 'var(--black)'};
	transition: color 0.3s;
	transition-delay: 0.5s;
	width: 100%;
`
const Container = styled.div`
	position: relative;
	width: 100%;
	${mobile}{
		top: -0.45vw;		
	}
`
const Background = styled.div`
	background-color: var(--white);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: calc(100% - 80px);
	:after{
		content: '';
		position: absolute;
		top: calc(100% - 60px);
		left: 0;
		right: 0;
		background: linear-gradient(180deg, #FFFFFF 54%, rgba(255, 255, 255, 0) 100%);
		height: 200px;
		z-index: -1;
	}
`
const StyledSection = styled(Section)`
	width: 100%;
	padding-top: 37px;
	${mobile}{
		padding-top: 21px;
	}
`
const Content = styled.div`
	grid-column: 5/13;
	display: flex;
	flex-direction: column;
	${mobile}{
		grid-column: span 12;
	}
`
const Flex = styled.div`
	display: flex;
`
const Relative = styled.div`
	width: 100%;
	position: relative;
`

const SearchInput = styled.input`
	margin-top: -0.53em;
	border: none;
	outline: none;
	border-bottom: 1px solid;
	border-color: ${props => props.error ? 'var(--red)' : props.white ? 'var(--white)' : 'var(--black)'};
	color: ${props => props.error ? 'var(--red)' : props.white ? 'var(--white)' : 'var(--black)'};
	background-color: transparent;
	width: 100%;
	box-sizing: border-box;
	height: 1.8em;
	font-family: 'America', serif;
	font-size: 1.1vw;
	text-transform: uppercase;
	line-height: 3em;
	border-radius: 0;
	${tablet}{
		font-size: 1.7vw;
	}
	${mobile}{
		font-size: 3.02vw;
		padding-bottom: 0em;
		bottom: -0.03em;
		padding: 0;
		line-height: 3.02vw;
		border-radius: 0;
	}
	::placeholder{
		color: ${props => props.error ? 'var(--red)' : props.white ? 'var(--white)' : 'var(--grey2)'};
	}
  &:-webkit-autofill{
		border-bottom: ${props => props.white ? '1px solid var(--white)' : '1px solid var(--black)'};
		-webkit-text-fill-color: ${props => props.white ? 'var(--white)' : 'var(--black)'};
		&:focus{
			-webkit-text-fill-color: ${props => props.white ? 'var(--white)' : 'var(--black)'};
		}
		:hover{
			border-bottom: ${props => props.white ? '1px solid var(--white)' : '1px solid var(--black)'};
			-webkit-text-fill-color: ${props => props.white ? 'var(--white)' : 'var(--black)'};
		}
	}
`
const SearchButton = styled.button`
	position: absolute;
	right: 0;
	top: 0;
`
const CloseBlock = styled.div`
	width: 0.45rem;
	margin-left: 2vw;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	${mobile}{
		margin-left: 0;
		width: 10vw;
		align-items: start;
	}
`
const Close = styled.button`
	width: 0.45rem;
	height: 0.45rem;
	>svg {
		width: 100%;
		display: block;
	}
	${mobile}{
		position: relative;
		top: 0.55vw;		
	}
`
const Results = styled(Section)`	
	padding-top: 1em;
`
const ResultsColumn = styled.div`
	grid-column: 5/13;
	${mobile}{
		grid-column: span 12;
	}
`
const Result = styled.div`
	padding-bottom: 0.2em;
`
Search.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
}

export default Search