import { useState, useEffect } from 'react'

const useScrolledTop = () => {
	const [top, setTop] = useState(false)

	useEffect(() => {
		if(window && window.pageYOffset < 10) {
			setTop(true)
		}
		window.addEventListener('scroll', () => {
			if(window.pageYOffset < 10) {
				setTop(true)
			} else {
				setTop(false)
			}
		})
	}, [])


	return top
}

export default useScrolledTop