import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Columns, Squares } from '~components/Svg'
import {useSiteState} from '~context/siteContext'
import useBreakpoint from '~utils/useBreakpoint'

const AnimatedButton = ({ active, children, className }) => {
	return (
		<ToggleButton className={`${className} sm`} active={active}>
			{children}
		</ToggleButton>
	)
}

const ToggleButton = styled.div`
	position: relative;
	color: ${props => props.active ? 'var(--white)' : 'var(--black)'};
	transition: color 0.3s;
	padding: 0 0.8vw;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 2.1em;
	${mobile}{
		min-height: 13px;
		width: 18px;
		padding: 0;
	}
`

const LayoutToggleMobile = ({ className }) => {

	const [siteState, setSiteState] = useSiteState()
	const { isMobile } = useBreakpoint()

	const toggleView = () => {
		if (siteState.view === 'index'){
			setSiteState(prevState => ({
				...prevState,
				view: 'gallery'
			}))} else {
			setSiteState(prevState => ({
				...prevState,
				view: 'index'
			}))} 
	}

	return (
		<Wrap className={className} onClick={isMobile ? () => toggleView() : null}>
			<FirstAnimatedButton 
				active={siteState.view === 'index'}>
				<ColumnsIcon />
			</FirstAnimatedButton>
			<SecondAnimatedButton 
				active={siteState.view === 'gallery'}>
				<SquaresIcon />
			</SecondAnimatedButton>
			<Pill right={siteState.view === 'gallery'}/>
			<GradientLeft show={siteState.view === 'index'}/>
			<GradientRight show={siteState.view === 'gallery'}/>
		</Wrap>
	)
}

const Wrap = styled.button`
	display: inline-flex;
	border-radius: 30px;
	min-height: 0.9em;
	position: relative;
	${mobile}{
		min-height: 12px;
	}
`
const FirstAnimatedButton = styled(AnimatedButton)`
	margin-right: -1px;
`
const SecondAnimatedButton = styled(AnimatedButton)`
	margin-left: -1px;
`
const GradientLeft = styled.div`
	background: linear-gradient(270deg, #D9D9D9 13.92%, rgba(217, 217, 217, 0) 50%);
	opacity: ${props => props.show ? '1' : '0'};
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	transition: opacity 0.3s;
	border-radius: 30px;
`
const GradientRight = styled.div`
	background: linear-gradient(270deg, #D9D9D9 13.92%, rgba(217, 217, 217, 0) 50%);
	transform: rotate(180deg);
	opacity: ${props => props.show ? '1' : '0'};
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	transition: opacity 0.3s;
	border-radius: 30px;
`
const Pill = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	background-color: var(--black);
	border-radius: 30px;
	z-index: 0;
	width: 54%;
	transform: ${props => props.right ? 'translate(88%)' : 'translate(0)'};
	transition: transform 0.25s;
`
const ColumnsIcon = styled(Columns)`
	display: none;
	width: 7px;
	position: relative;
	z-index: 2;
	${mobile}{
		display: block
	}
`
const SquaresIcon = styled(Squares)`
	display: none;
	width: 7px;
	position: relative;
	z-index: 2;
	${mobile}{
		display: block
	}
`

LayoutToggleMobile.propTypes = {
	className: PropTypes.string
}

AnimatedButton.propTypes = {
	active: PropTypes.bool,
	children: PropTypes.node,
	onClick: PropTypes.func,
	className: PropTypes.string,
}


export default LayoutToggleMobile