import React from 'react'
import PropTypes from 'prop-types'
import GlobalStyles from '~styles/global'
import '~styles/static.css'
import Header from '~components/Header'
import { useLocation } from '@reach/router'
import Transition from '~components/Transition'
import { css } from '@emotion/react'
import { mobile } from '~styles/global'
import Footer from '~components/Footer'

const Layout = ({ children }) => {

	const location = useLocation()

	const projectIndex = (location?.pathname === '/projects' || location?.pathname === '/projects/')
	const projectTempate = location.pathname.includes('/projects/') && !projectIndex

	return (
		<>
			<GlobalStyles />
			<Header 
				projectIndex={projectIndex} 
				home={location?.pathname === '/'}
			/>
			<main>
				<Transition>
					<div css={css`
					min-height: calc(100vh);
					display: flex;
					flex-direction: column;
					${mobile}{
						min-height: calc(100vh);
					}
				`}>
						{children}
						{(!location.pathname.includes('/info') && !projectTempate)&& <Footer />} 
					</div>
				</Transition>
			</main>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout
