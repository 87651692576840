module.exports = {
	siteUrl: 'https://evi-o.studio',
	buildsUrl: 'https://evio.gtsb.io',
	previewUrl: 'https://preview-evio.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/3f9906bd-0027-413e-b2cb-6b929fa9b0f7',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'G-XXXXXXXXX'
}
