import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'
import { Uarr } from '~components/Svg'
import { tablet } from '~styles/global'
import useBreakpoint from '~utils/useBreakpoint'

const Footer = ({ className }) => {

	const { acknowledgement } = useSiteSettings()
	const { isTablet } = useBreakpoint()

	return(
		<Wrap className={className}>
			<InnerSection>
				<BackToTop 
					onClick={() => window.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth'
					})}>
						↑
				</BackToTop>
				<Ack className={isTablet ? 'xs' : 'sm'}>
					<RichText content={acknowledgement}/>
				</Ack>
				<Credits className={isTablet ? '' : 'sm'}>
					EVI-O.STUDIO © {new Date().getFullYear()}
				</Credits>
			</InnerSection>
		</Wrap>
	)
}

const Wrap = styled.footer`
	margin-top: auto;
	position: relative;
	min-height: 130px;
	z-index: 2;
	font-size: 13px;
	display: flex;
	${tablet}{
		height: auto;
		margin-bottom: 5.5vw;
	}
`
const InnerSection = styled(Section)`
	height: auto;
	margin-top: 60px;
	>div{
		height: 100%;
	}
`
const ArrowUp = styled(Uarr)`
`
const BackToTop = styled.button`
	grid-column: span 4;
	max-width: 29px;
	font-size: 3vw;
	text-align: left;
	${tablet}{
		grid-column: 1/3;
		grid-row: 2;
	}
`
const Ack = styled.div`
	grid-column: 5/9;
	align-self: end;
	padding-bottom: 18px;
	${tablet}{
		grid-column: 1/13;
		grid-row: 1;
		margin-bottom: 3.5vw;
		padding-bottom: 0;
	}
	
`
const Credits = styled.div`
	grid-column: 11/13;
	text-align: right;
	align-self: end;
	padding-bottom: 18px;
	${tablet}{
		white-space: nowrap;
		grid-column: 4/13;
		grid-row: 2;
		padding-bottom: 0.3em;
	}
`

Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
