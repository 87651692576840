/* eslint-disable react/prop-types */
import React from 'react'

export const Face = ({ className }) => (
	<svg className={className} viewBox="0 0 99 106" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="20" cy="20" r="20" fill="currentColor"/>
		<path d="M49.5 105.5C76.8381 105.5 99 83.3381 99 56H0C0 83.3381 22.1619 105.5 49.5 105.5Z" fill="currentColor"/>
		<circle cx="79" cy="20" r="20" fill="currentColor"/>
	</svg>
)
export const Logo = ({ className }) =>
	<svg className={className} viewBox="0 0 801 752" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M521.618 501.357C517.468 495.867 509.048 496.477 505.728 502.507C462.098 581.917 409.928 634.557 312.868 627.487C220.968 620.797 168.468 554.297 169.458 459.347C170.448 364.397 214.458 308.347 284.678 299.617C345.368 292.067 436.728 322.677 451.948 441.497C452.618 446.717 459.318 448.477 462.468 444.267C476.198 425.897 497.438 385.697 467.148 339.177C441.198 299.337 341.758 261.737 256.408 278.937C239.448 282.357 221.848 277.317 209.568 265.137C164.198 220.137 194.908 145.927 252.028 141.867C309.248 137.797 370.218 206.487 410.768 177.477C438.748 157.457 427.748 122.797 421.528 107.367C420.038 103.667 414.478 103.557 413.298 107.367C402.398 142.587 378.778 159.057 334.148 139.957C267.878 111.597 211.958 105.607 167.448 151.597C129.068 191.257 138.748 249.507 168.838 282.377C176.208 290.427 186.318 297.417 197.048 302.507C171.188 319.307 148.328 340.507 133.568 380.727C74.8782 540.697 209.018 647.107 321.268 646.777C419.428 646.487 583.718 583.537 521.618 501.357Z" fill="currentColor"/>
		<path d="M274.238 360.677C227.208 380.057 208.868 443.797 233.278 503.047C257.688 562.297 315.608 594.617 362.638 575.237C409.668 555.857 428.008 492.117 403.598 432.867C379.188 373.617 321.278 341.307 274.238 360.677ZM339.448 570.077C311.908 575.747 280.178 534.617 268.578 478.217C256.978 421.817 269.898 371.507 297.438 365.847C324.978 360.177 356.708 401.307 368.308 457.707C379.908 514.097 366.988 564.407 339.448 570.077Z" fill="currentColor"/>
		<path d="M679.048 535.047C677.118 548.687 673.348 560.657 668.848 569.977C645.118 619.207 580.438 643.477 519.718 643.717C506.288 643.767 506.198 631.107 513.558 627.967C606.568 588.207 621.288 509.847 596.808 443.757C596.578 443.147 596.358 442.537 596.118 441.937C596.008 441.627 595.888 441.317 595.758 441.007C595.648 440.707 595.528 440.417 595.408 440.117C595.328 439.907 595.238 439.697 595.158 439.487C576.868 394.907 515.838 343.917 515.838 343.917L515.988 343.877C477.948 310.087 462.138 281.947 456.408 260.247C456.408 260.247 456.408 260.247 456.418 260.247C443.558 206.797 469.058 169.317 490.778 150.997C506.438 137.787 533.068 127.537 553.288 125.167C594.698 120.317 668.738 138.357 679.778 216.437C688.028 274.857 637.968 294.957 616.608 299.917C613.598 300.617 611.398 297.127 613.318 294.697C622.268 283.387 637.038 256.367 627.318 222.117C614.468 176.857 580.048 166.827 554.938 165.877C510.058 164.167 473.768 200.107 478.108 242.657C478.468 246.227 479.118 249.847 480.078 253.497L480.158 253.597C480.158 253.597 484.428 290.277 561.778 344.537C640.208 399.527 689.928 464.247 679.048 535.047Z" fill="currentColor"/>
		<path d="M358.478 30.2774C113.738 -72.8026 -75.3718 102.157 29.6582 331.157C42.6182 359.417 42.6182 391.907 29.6582 420.167C-75.4118 649.277 113.908 824.287 358.818 720.917C385.158 709.797 414.848 709.797 441.188 720.917C686.098 824.287 875.418 649.277 770.348 420.167C757.388 391.907 757.388 359.417 770.348 331.157C875.368 102.157 686.268 -72.8026 441.518 30.2774C414.958 41.4574 385.038 41.4574 358.478 30.2774ZM349.038 702.857C150.818 754.967 -1.2718 614.617 49.9182 429.387C59.6282 394.247 59.6282 357.067 49.9182 321.927C-1.2718 136.697 150.818 -3.64262 349.038 48.4574C382.428 57.2374 417.578 57.2374 450.968 48.4574C649.188 -3.65262 801.268 136.697 750.088 321.927C740.378 357.067 740.378 394.247 750.088 429.387C801.278 614.617 649.188 754.967 450.968 702.857C417.568 694.087 382.428 694.087 349.038 702.857Z" fill="currentColor"/>
	</svg>


export const Columns = ({ className }) => (
	<svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path fillRule="evenodd" clipRule="evenodd" d="M0.5 7.5L0.5 0.5H1.5L1.5 7.5H0.5ZM2.5 7.5L2.5 0.5H3.5L3.5 7.5H2.5ZM4.5 7.5L4.5 0.5H5.5L5.5 7.5H4.5ZM6.5 7.5L6.5 0.5H7.5L7.5 7.5H6.5Z" fill="currentColor"/>
	</svg>
)

export const Squares = ({ className }) => (
	<svg viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path fillRule="evenodd" clipRule="evenodd" d="M0 0H3V3H0V0ZM0 4V7H3V4H0ZM4 4V7H7V4H4ZM7 3V0H4V3H7Z" fill="currentColor"/>
	</svg>
)

export const EmailIcon = ({ className }) => (
	<svg viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M16.4201 1.13797C14.7012 -0.587834 11.5684 -0.303666 9.04557 1.67165C8.96906 1.72888 8.87754 1.76259 8.78219 1.76868C8.6864 1.76474 8.59427 1.73079 8.51882 1.67165V1.67165C6.00289 -0.303666 2.86318 -0.587834 1.13737 1.13797C-0.435947 2.71129 -0.338914 5.4698 1.19282 7.84711C1.34157 8.08243 1.40969 8.35978 1.38689 8.63724C1.38343 8.70188 1.38343 8.76666 1.38689 8.8313C1.38787 9.31554 1.50934 9.79191 1.74037 10.2175C1.7709 10.2955 1.7709 10.3821 1.74037 10.4601C0.749242 14.4246 3.95133 17.3494 7.9782 15.9078C8.20087 15.8286 8.435 15.7865 8.6713 15.783C8.90774 15.7849 9.14213 15.8271 9.36439 15.9078V15.9078C13.4328 17.3494 16.628 14.4246 15.6438 10.4601C15.6126 10.3822 15.6126 10.2953 15.6438 10.2175C15.8748 9.79191 15.9963 9.31554 15.9973 8.8313C16.0008 8.76666 16.0008 8.70188 15.9973 8.63724C15.982 8.35857 16.0548 8.08217 16.2052 7.84711C17.8964 5.4698 18.0211 2.71129 16.4201 1.13797ZM15.5676 8.13821L15.3527 8.46396C15.2931 8.5404 15.2526 8.62994 15.2345 8.72517C15.2165 8.82039 15.2213 8.91854 15.2488 9.01151C15.2908 9.15559 15.3118 9.30499 15.3111 9.45509C15.3189 9.69521 15.2664 9.93346 15.1587 10.1482C15.124 10.2338 15.1098 10.3264 15.1171 10.4185C15.3874 13.2463 13.1695 15.7484 9.4337 15.2701C8.99229 15.1598 8.53051 15.1598 8.0891 15.2701C4.37412 15.6999 2.15622 13.1701 2.4196 10.3977C2.43362 10.3055 2.41911 10.2111 2.37801 10.1274C2.27262 9.91205 2.22259 9.67383 2.23246 9.43429C2.23375 9.29117 2.25474 9.1489 2.29484 9.01151C2.32191 8.92162 2.32786 8.82669 2.31223 8.73412C2.2966 8.64156 2.25981 8.55385 2.20474 8.47782L1.98988 8.15207C0.783897 6.18369 0.839344 3.81331 2.27405 2.3786C3.83351 0.819145 6.48805 0.881523 8.51189 2.42019L8.57426 2.46871C8.63061 2.50973 8.69863 2.53159 8.76833 2.53109V2.53109C8.81088 2.53721 8.85422 2.53485 8.89586 2.52414C8.93749 2.51344 8.97659 2.4946 9.01091 2.46871L9.08022 2.42019C11.111 0.881523 13.7586 0.819145 15.3181 2.3786C16.725 3.81331 16.7736 6.18369 15.5676 8.13821Z" fill="currentColor"/>
		<path d="M13.1136 6.96648C13.1048 6.94039 13.088 6.91771 13.0656 6.90165C13.0432 6.88559 13.0164 6.87695 12.9888 6.87695C12.9613 6.87695 12.9344 6.88559 12.912 6.90165C12.8897 6.91771 12.8729 6.94039 12.8641 6.96648C12.3476 8.39235 11.3064 9.56737 9.95308 10.2517C9.89466 10.2755 9.84609 10.3185 9.81537 10.3735C9.78466 10.4286 9.77364 10.4925 9.78414 10.5547C9.79464 10.6169 9.82603 10.6736 9.87312 10.7156C9.92021 10.7575 9.9802 10.7822 10.0432 10.7854C10.7744 10.849 11.5093 10.715 12.171 10.3973C12.8641 10.0438 13.0165 9.24676 12.3096 9.323C12.6291 9.02811 12.8699 8.65815 13.0103 8.24666C13.1507 7.83517 13.1862 7.39515 13.1136 6.96648V6.96648Z" fill="currentColor"/>
		<path d="M7.62426 10.2314C6.2626 9.5451 5.21405 8.36517 4.69247 6.9323C4.68365 6.90621 4.66687 6.88353 4.64449 6.86747C4.62211 6.85141 4.59526 6.84277 4.56772 6.84277C4.54017 6.84277 4.51332 6.85141 4.49094 6.86747C4.46856 6.88353 4.45178 6.90621 4.44296 6.9323C4.36919 7.3631 4.40408 7.8055 4.54447 8.21941C4.68486 8.63332 4.92631 9.00565 5.24695 9.30268C4.55385 9.22644 4.66475 9.99577 5.3925 10.377C6.05486 10.6832 6.78749 10.8053 7.51336 10.7305C7.56709 10.7207 7.61674 10.6954 7.65611 10.6575C7.69547 10.6197 7.72278 10.5711 7.73463 10.5178C7.74647 10.4645 7.74232 10.4089 7.72268 10.3579C7.70305 10.307 7.66881 10.263 7.62426 10.2314V10.2314Z" fill="currentColor"/>
		<path d="M9.26693 9.75999C9.20043 8.92649 9.40198 8.09356 9.84219 7.38268C10.1017 6.9479 10.4675 6.58623 10.9052 6.33163C11.3428 6.07703 11.838 5.93785 12.3443 5.92718C12.5453 4.88754 11.6512 4.54099 10.8957 4.71427C10.4709 4.81178 10.0805 5.02255 9.76595 5.32419C9.78938 5.24192 9.81714 5.16095 9.84913 5.08161C9.91148 4.90442 9.91148 4.71125 9.84913 4.53406C9.77223 4.30161 9.62402 4.09931 9.42556 3.95592C9.2271 3.81253 8.98848 3.73535 8.74364 3.73535C8.4988 3.73535 8.26019 3.81253 8.06173 3.95592C7.86326 4.09931 7.71505 4.30161 7.63816 4.53406C7.57581 4.71125 7.57581 4.90442 7.63816 5.08161C7.67014 5.16095 7.6979 5.24192 7.72133 5.32419C7.41449 5.02686 7.03419 4.81638 6.61931 4.71427C5.92622 4.54793 4.96975 4.88754 5.17075 5.92718C5.67711 5.9372 6.17257 6.0761 6.61035 6.33077C7.04813 6.58543 7.41379 6.94746 7.67281 7.38268C8.11623 8.09221 8.31805 8.92622 8.24808 9.75999C8.19369 10.5257 8.05658 11.2833 7.83916 12.0195C7.76653 12.2664 7.68093 12.5093 7.58271 12.7472C7.60546 13.0432 7.7391 13.3197 7.95691 13.5214C8.17472 13.7231 8.46064 13.8352 8.7575 13.8352C9.05437 13.8352 9.34029 13.7231 9.55809 13.5214C9.7759 13.3197 9.90954 13.0432 9.9323 12.7472C9.9323 12.7472 9.8214 12.4769 9.70358 12.0195C9.47633 11.2844 9.32992 10.5268 9.26693 9.75999V9.75999Z" fill="currentColor"/>
	</svg>
)

export const AddressIcon = ({ className }) => (
	<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M12.8365 7.10459C12.5593 10.4522 8.8928 10.7988 8.8928 10.7988H8.78191H8.67101C8.67101 10.7988 4.99762 10.4522 4.72038 7.10459C3.46588 6.93131 3.05003 8.06799 3.29954 8.94821C3.8956 11.1245 6.39767 11.5473 7.77692 11.6166C7.40135 11.6657 7.04811 11.8228 6.76018 12.0689C6.47225 12.315 6.26203 12.6395 6.15509 13.0028C6.05112 13.4533 6.26598 14.0424 6.91749 13.9108C6.9868 12.3374 8.57398 11.9978 8.83043 11.9493C9.30142 12.0224 9.73475 12.2499 10.0622 12.5962C10.3897 12.9425 10.5928 13.3879 10.6394 13.8622C11.2909 13.9939 11.5127 13.4048 11.4018 12.9543C11.2962 12.6066 11.0943 12.2958 10.8196 12.0579C10.5449 11.82 10.2085 11.6646 9.84927 11.6097C11.2355 11.5335 13.6682 11.0829 14.2643 8.94821C14.5069 8.06799 14.1257 6.93131 12.8365 7.10459Z" fill="currentColor"/>
		<path d="M8.78186 10.632C9.16999 10.1676 10.168 8.96859 10.6948 8.20619C12.3998 5.75957 11.1245 3.24364 8.83037 3.20898H8.73334C6.4392 3.20898 5.15698 5.75957 6.86199 8.20619C7.38874 8.96859 8.39373 10.1676 8.78186 10.632ZM8.7472 3.88128H8.80958C10.1958 3.88128 10.9512 5.41302 9.93239 6.87545C9.61357 7.33289 9.01058 8.05371 8.78186 8.33094C8.54621 8.05371 7.95015 7.33289 7.63132 6.87545C6.60555 5.41302 7.37488 3.90208 8.7472 3.88128Z" fill="currentColor"/>
		<path d="M13.2797 4.15856C12.5866 1.71194 10.8261 0 8.78149 0C6.73687 0 4.98334 1.73273 4.27639 4.15856C1.78125 4.85165 0 6.6329 0 8.71218C0 10.7915 1.76046 12.5311 4.2348 13.2242C4.92789 15.754 6.68835 17.5561 8.78149 17.5561C10.8746 17.5561 12.649 15.754 13.3213 13.2242C15.7956 12.5311 17.5561 10.7707 17.5561 8.71218C17.5561 6.65369 15.7817 4.85165 13.2797 4.15856ZM13.1688 12.7113L12.9331 12.7945C12.9331 12.8777 12.8777 12.9608 12.843 13.044C12.5691 13.912 12.0368 14.6757 11.3172 15.2331C10.5976 15.7904 9.72506 16.1148 8.81615 16.1629C7.90602 16.1162 7.03193 15.7924 6.31098 15.235C5.59003 14.6775 5.05666 13.9131 4.78234 13.044L4.69917 12.7945L4.46352 12.7113C3.60599 12.4347 2.85265 11.9043 2.30315 11.1902C1.75365 10.4761 1.4339 9.61196 1.38619 8.71218C1.43157 7.81092 1.75034 6.94484 2.30007 6.2292C2.8498 5.51356 3.60442 4.9823 4.46352 4.7061L4.71303 4.60907C4.71303 4.53283 4.77541 4.44966 4.80314 4.36649C5.09363 3.52973 5.6276 2.79891 6.33649 2.26786C7.04538 1.73681 7.89682 1.42978 8.78149 1.38619C9.66521 1.43021 10.5156 1.73751 11.2233 2.2686C11.931 2.79968 12.4637 3.53028 12.7529 4.36649C12.7876 4.44966 12.8153 4.53283 12.843 4.60907L13.0995 4.7061C13.956 4.98462 14.7076 5.51689 15.2547 6.23233C15.8018 6.94778 16.1186 7.81262 16.1629 8.71218C16.1205 9.60162 15.8126 10.4579 15.2789 11.1707C14.7452 11.8836 14.0103 12.4201 13.1688 12.7113V12.7113Z" fill="currentColor"/>
	</svg>
)

export const InstaIcon = ({ className }) => (
	<svg viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M16.9964 9.26168C16.8558 8.95261 16.7831 8.61702 16.7831 8.27749C16.7831 7.93796 16.8558 7.60236 16.9964 7.2933C19.3182 2.24065 15.1458 -1.61988 9.74661 0.681186C9.45615 0.804525 9.14382 0.86809 8.82826 0.86809C8.5127 0.86809 8.20037 0.804525 7.90991 0.681186C2.51072 -1.61988 -1.6617 2.24065 0.653228 7.2933C0.796944 7.60149 0.871419 7.93743 0.871419 8.27749C0.871419 8.61755 0.796944 8.95348 0.653228 9.26168C-1.6617 14.3143 2.51765 18.1749 7.91684 15.9292C8.20574 15.8093 8.51546 15.7476 8.82826 15.7476C9.14106 15.7476 9.45078 15.8093 9.73968 15.9292C15.1389 18.1749 19.3182 14.3143 16.9964 9.26168ZM16.5528 9.46268C17.6825 13.5519 14.328 16.6431 9.95454 15.4926C9.21535 15.3054 8.44117 15.3054 7.70199 15.4926C3.32857 16.6223 0.0017203 13.5519 1.10374 9.46268C1.31876 8.68718 1.31876 7.8678 1.10374 7.0923C0.0017203 3.00305 3.32857 -0.0950787 7.70199 1.05546C8.44029 1.24959 9.21624 1.24959 9.95454 1.05546C14.328 -0.0950787 17.6825 3.00305 16.5528 7.0923C16.3378 7.8678 16.3378 8.68718 16.5528 9.46268V9.46268Z" fill="currentColor"/>
		<path d="M13.4755 3.03808C12.7848 2.65748 11.9889 2.51247 11.2085 2.62504C10.428 2.73761 9.70549 3.10162 9.15056 3.66186V3.66186C9.07432 3.73555 8.97243 3.77673 8.8664 3.77673C8.76036 3.77673 8.65847 3.73555 8.58223 3.66186V3.66186C8.06645 3.13658 7.40399 2.77942 6.6817 2.63718C5.9594 2.49495 5.21098 2.57429 4.53456 2.8648C3.86807 3.17493 3.30833 3.67547 2.92596 4.30331C2.54358 4.93115 2.35569 5.65816 2.38597 6.39265C2.45352 7.49345 2.85485 8.54755 3.53651 9.41454C4.88111 11.3344 7.07821 13.0186 8.82481 14.2662C10.5091 13.1057 12.0595 11.7622 13.4477 10.2601C14.2488 9.38969 14.833 8.34243 15.1528 7.20357C15.3479 6.42108 15.2907 5.59694 14.9895 4.84886C14.6883 4.10078 14.1584 3.46701 13.4755 3.03808V3.03808ZM14.0923 7.38377C13.8157 8.34737 13.3172 9.23277 12.6368 9.96901C11.5123 11.361 10.236 12.6233 8.83174 13.7325C7.13623 12.4566 5.64915 10.9251 4.42367 9.19275C3.86535 8.4897 3.53202 7.63462 3.4672 6.7392C3.4401 6.18949 3.57723 5.64416 3.86112 5.17266C4.14501 4.70116 4.56281 4.32482 5.06131 4.09158C5.37259 3.95929 5.70729 3.89093 6.04551 3.89058C6.79749 3.90244 7.51554 4.20556 8.04855 4.73616V4.73616C8.25669 4.93771 8.53507 5.05041 8.82481 5.05041C9.11455 5.05041 9.39293 4.93771 9.60107 4.73616C10.0111 4.31865 10.5453 4.04509 11.1237 3.95639C11.7022 3.86769 12.2938 3.96861 12.8101 4.24406C13.3289 4.56401 13.7327 5.04052 13.9632 5.60484C14.1936 6.16915 14.2389 6.79209 14.0923 7.38377V7.38377Z" fill="currentColor"/>
		<path d="M11.091 7.89632C10.8703 7.77052 10.6207 7.70436 10.3667 7.70436C10.1127 7.70436 9.8631 7.77052 9.64244 7.89632C9.38756 8.04974 9.17891 8.26924 9.03859 8.53157C8.89828 8.7939 8.83152 9.08929 8.84538 9.38647C8.85978 9.08157 8.78894 8.77869 8.64081 8.5118C8.49268 8.24492 8.27311 8.02458 8.00674 7.87552C7.79282 7.7625 7.55553 7.70081 7.31365 7.69532C7.1255 7.69474 6.9392 7.73247 6.7661 7.80621C6.48973 7.93752 6.25855 8.14791 6.10185 8.41072C5.94516 8.67353 5.86999 8.97694 5.88587 9.2825C5.91338 9.78803 6.09747 10.2725 6.41263 10.6687C7.09622 11.6368 7.92631 12.4926 8.87311 13.2054C9.65127 12.5889 10.3622 11.8919 10.994 11.1261C11.3626 10.7139 11.6327 10.2233 11.7841 9.69143C11.8776 9.35748 11.8606 9.00226 11.7357 8.67875C11.6108 8.35524 11.3847 8.08078 11.091 7.89632V7.89632Z" fill="currentColor"/>
	</svg>
)

export const Uarr = ({ className }) => (
	<svg viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M28.881 14.8209L14.572 0.470947L0.263 14.8209L2.682 17.2399L12.85 7.07195V31.7129H16.294V7.07195L26.462 17.2399L28.881 14.8209Z" fill="currentColor"/>
	</svg>
)

export const Cross = ({ className }) => (
	<svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M7.40938 1.80016L6.23938 0.660156L4.06438 2.91016L1.88937 0.660156L0.749375 1.80016L2.95438 4.08016L0.734375 6.37516L1.87437 7.51516L4.07938 5.25016L6.26938 7.51516L7.40938 6.36016L5.20437 4.08016L7.40938 1.80016Z" fill="currentColor"/>
	</svg>
)

export const ChevDownSmall = ({ className }) => (
	<svg viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M0.5 0L2.557 4.389H3.767L5.824 2.3272e-07L4.493 1.74539e-07L3.173 3.069L1.853 5.91426e-08L0.5 0Z" fill="currentColor"/>
	</svg>
)

export const Dash = ({ className }) => (
	<svg viewBox="0 0 5 1" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path fillRule="evenodd" clipRule="evenodd" d="M0 0H4.39V1H0V0Z" fill="currentColor"/>
	</svg>
)

export const MagnifyingGlass = ({ className }) => (
	<svg className={className} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.235 10.0293C11.2483 12.0107 8.01767 12.0133 6.03233 10.0293C5.06967 9.06666 4.54033 7.788 4.54033 6.42667C4.54033 5.06667 5.06967 3.788 6.03233 2.82667C7.95633 0.899999 11.311 0.899999 13.235 2.82667C14.1977 3.788 14.727 5.06667 14.727 6.42667C14.727 7.788 14.1977 9.06666 13.235 10.0293ZM14.1777 1.884C12.9643 0.669332 11.3497 -1.58588e-06 9.63367 -1.58588e-06C7.91633 -1.58588e-06 6.303 0.669332 5.08967 1.884C3.875 3.096 3.207 4.71067 3.207 6.42667C3.207 7.93333 3.74833 9.344 4.69367 10.4907C4.623 10.524 4.55367 10.564 4.495 10.6227L0.195 14.9227C-0.065 15.1827 -0.065 15.6053 0.195 15.8653C0.325667 15.996 0.496333 16.06 0.667 16.06C0.837667 16.06 1.00833 15.996 1.13767 15.8653L5.43767 11.5653C5.49767 11.5067 5.539 11.4373 5.571 11.3653C6.75233 12.336 8.18567 12.8507 9.63367 12.8507C11.279 12.8507 12.9257 12.224 14.1777 10.972C15.391 9.75866 16.0603 8.144 16.0603 6.42667C16.059 4.71067 15.391 3.096 14.1777 1.884Z" fill="currentColor"/>
	</svg>
)
