import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import SanityLink from '~components/SanityLink'
import { motion } from 'framer-motion'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	mobileUp: '@media (min-width: 769px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const mobileUp = breakpoints.mobileUp
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp


const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
  body, html {
    font-family: 'La Nord', sans-serif;
		font-size: 1.70vw;
		background-color: var(--white);
		${tablet}{
			font-size: 2.2vw;
		}
		${mobile}{
			font-size: 3.3vw;
		}
  }

  :root{
    --white: #ffffff;
    --black: #000000;
		--grey: #D9D9D9;
		--grey2: #B2B2B2;
		--red: red;
    --xxs: 5px;
    --xs: 10px;
    --s: 20px;
    --m: 30px;
    --l: 40px;
    --xl: 50px;
    --xxl: 80px;
    --xxxl: 100px;
    --xxxxl: 150px;
		--headerHeight: 6.9rem;
		--topPadding: 5.88rem;
  }

  ${tablet}{
    :root{
      --xxs: 3px;
      --xs: 7px;
      --s: 15px;
      --m: 20px;
      --l: 30px;
      --xl: 35px;
      --xxl: 40px;
      --xxxl: 60px;
      --xxxxl: 120px;
    }
		
  }

  p{
    margin-bottom: 0.5em;
    &:last-child{
      margin-bottom: 0;
    }
  }

  h3,
  .h3 {
		font-size: 1.2vw;
		font-family: 'America', serif;
		text-transform: uppercase;
    ${tablet}{
			font-size: 1.9vw;
		}
		${mobile}{
			font-size: 3.3vw;
		}
  }

	h4,
  .h4 {
		font-size: 1.1vw;
		font-family: 'America', serif;
		text-transform: uppercase;
		/* line-height: 1.23; */
    ${tablet}{
			font-size: 1.7vw;
		}
		${mobile}{
			font-size: 3.02vw;
		}
  }
	
	.md {
		font-size: 0.9vw;
		${tablet}{
			font-size: 1.41vw;
		}
		${mobile}{
			font-size: 2.471vw;
		}
	}

	.sm {
		font-size: 0.75vw;
		letter-spacing: 0.03em;
		line-height: 1.2;
		${tablet}{
			font-size: 1.18vw;
		}
		${mobile}{
			font-size: 2.06vw;
		}
	}

	.xs {
		font-size: 0.65em;
	}

	.nav {
		letter-spacing: 0.03em;
		${mobile}{
			font-size: 2.6vw;
		}
	}

	html {
		background: black;
	}

	* {
		overflow-anchor: none;
	}

	.ReactCollapse--collapse {
		transition: height 0.6s cubic-bezier(0.5, 1, 0.89, 1);
	}

	.hide-mobile {
		${mobile}{
			display: none;
		}
	}
	.hide-desktop {
		${mobileUp}{
			display: none;
		}
	}
`

export const ScrollWrap = styled.div`
	display: flex;
	position: fixed;
	top: ${props => props.headerHeight}px;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--white);
	${mobile}{
		display: block;
	}
`
export const Main = styled.div`
	width: 66vw;
	overflow-y: scroll;
	position: relative;
	z-index: 9;
	box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
	padding: 1.6vw 2vw 0;
	box-sizing: border-box;
	flex: 1;
	${mobile}{
		width: 100%;
		overflow: auto;
		:after{
			content: '';
			position: fixed;
			left: 0;
			top: -100px;
			height: 100px;
			width: 91.5vw;
			background-color: white;
			z-index: 100;
		}
	}
`
export const Sidebar = styled(motion.div)`
	font-size: 0.65em;
	padding: 3.6vw 2vw 0;
	box-sizing: border-box;
	overflow-y: ${props => props.open ? 'scroll' : 'hidden'};
	display: flex;
	flex-direction: column;
	position: relative;
`
export const ShadowSoftener = styled(motion.div)`
	position: absolute;
	top: -1px;
	right: 0px;
	z-index: 9;
`
export const ShadowSoftenerGradient = styled.div`
	width: 1.8vw;
	height: 3vw;
	background: linear-gradient(180deg, #FFFFFF 5%, rgba(255, 255, 255, 0) 100%);
`
export const HeaderOverlay = styled.div`
	position: fixed;
	top: 0px;
	right: 0;
	bottom: 0;
	left: 0;
	height: ${props => props.headerHeight}px;
	background-color: var(--white);
	z-index: 3;
	transition: height 0.3s;
	${mobile}{
		display: none;
	}
`
export const TextSection = styled.div`
	border-bottom: 1px solid #000;
	margin-top: 2vw;
	h3{
		margin-bottom: 0.2em;
	}
	${mobile}{
		margin-top: 3.5vw;
	}
`
export const SidebarSection = styled.section`
	margin-bottom: 2.5em;
	h4{
		padding: 0em 0 0.2em;
	}
	> span {
		display: block;
		border-top: 1px solid #000;
		padding: 0.18em 0 0.05em;
	}
`
export const TopGradient = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: ${props => props.open ? '95vw' : '66vw'};
	height: 5vw;
	background: linear-gradient(180deg, white 5%, rgba(255, 255, 255, 0) 100%);
	pointer-events: none;
	opacity: ${props => props.scrolled < 100 ? props.scrolled / 100 : 1};
	z-index: 8;
	margin-top: ${props => props.headerHeight}px;
	transition: width 0.25s;
	${mobile}{
		/* display: none; */
		width: 100vw;
		height: 20vw;
	}
`
export const TopGradientSidebar = styled(TopGradient)`
	right: 0;
	width: calc(34vw - 15px);
	z-index: 101;
	left: auto;
`

export const Input = styled.input`
	border: none;
	outline: none;
	border-bottom: 1px solid;
	border-color: ${props => props.error ? 'var(--red)' : props.white ? 'var(--white)' : 'var(--black)'};
	color: ${props => props.error ? 'var(--red)' : props.white ? 'var(--white)' : 'var(--black)'};
	background-color: transparent;
	width: 100%;
	box-sizing: border-box;
	padding: 0;
	height: 1.3rem;
	border-radius: 0;
	display: block;
	line-height: 3em;
	font-size: 1.1vw;
	font-family: 'America', serif;
	text-transform: uppercase;
	${tablet}{
		font-size: 1.7vw;
	}
	${mobile}{
		font-size: 3.02vw;
		height: 1.6rem;
		line-height: normal;
	}
	::placeholder{
		color: ${props => props.error ? 'var(--red)' : props.white ? 'var(--white)' : 'var(--grey2)'};
	}
  &:-webkit-autofill{
		border-bottom: ${props => props.white ? '1px solid var(--white)' : '1px solid var(--black)'};
		-webkit-text-fill-color: ${props => props.white ? 'var(--white)' : 'var(--black)'};
		&:focus{
			-webkit-text-fill-color: ${props => props.white ? 'var(--white)' : 'var(--black)'};
		}
		:hover{
			border-bottom: ${props => props.white ? '1px solid var(--white)' : '1px solid var(--black)'};
			-webkit-text-fill-color: ${props => props.white ? 'var(--white)' : 'var(--black)'};
		}
	}
`

const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	/* Prevent safari blue links */
	button, option {
    color: inherit;
  }
`

export default GlobalStyles
