import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { motion, useAnimation, AnimateSharedLayout } from 'framer-motion'
import { Columns, Squares } from '~components/Svg'
import {useSiteState} from '~context/siteContext'

const AnimatedButton = ({ active, children, onClick, className }) => {
	return (
		<ToggleButton onClick={onClick} className={`${className} sm`} active={active}>
			{children}
			{active && 
					<Shadow 
						layoutId="underline"
						transition={{
							type: 'spring',
							stiffness: 200,
							damping: 40
						}}
					/>
			}
		</ToggleButton>
	)
}

const ToggleButton = styled.button`
	position: relative;
	color: ${props => props.active ? 'var(--white)' : 'var(--black)'};
	transition: color 0.3s;
	padding: 0 0.8vw;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 2.1em;
	position: relative;
	z-index: 1;
	${mobile}{
		min-height: 13px;
		width: 18px;
		padding: 0;
	}
`
const Shadow = styled(motion.div)`
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	background-color: var(--black);
	border-radius: 30px;
	z-index: 0;
`

const LayoutToggle = ({ className }) => {

	const [siteState, setSiteState] = useSiteState()

	return (
		<Wrap className={className} >
			<AnimateSharedLayout>
				<FirstAnimatedButton 
					onClick={() => 
						setSiteState(prevState => ({
							...prevState,
							view: 'index'
						}))} 
					active={siteState.view === 'index'}>
					<Text>Index</Text>
					<ColumnsIcon />
				</FirstAnimatedButton>
				<SecondAnimatedButton 
					onClick={() => 
						setSiteState(prevState => ({
							...prevState,
							view: 'gallery'
						}))} 
					active={siteState.view === 'gallery'}>
					<Text>Gallery</Text>
					<SquaresIcon />
				</SecondAnimatedButton>
			</AnimateSharedLayout>
			<GradientLeft show={siteState.view === 'index'}/>
			<GradientRight show={siteState.view === 'gallery'}/>
		</Wrap>
	)
}

const Wrap = styled.div`
	display: inline-flex;
	border-radius: 30px;
	min-height: 0.9em;
	position: relative;
`
const FirstAnimatedButton = styled(AnimatedButton)`
	margin-right: -1px;
`
const SecondAnimatedButton = styled(AnimatedButton)`
	margin-left: -1px;
`
const GradientLeft = styled.div`
	background: linear-gradient(270deg, #D9D9D9 13.92%, rgba(217, 217, 217, 0) 50%);
	opacity: ${props => props.show ? '1' : '0'};
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	transition: opacity 0.3s;
	border-radius: 30px;
`
const GradientRight = styled.div`
	background: linear-gradient(270deg, #D9D9D9 13.92%, rgba(217, 217, 217, 0) 50%);
	transform: rotate(180deg);
	opacity: ${props => props.show ? '1' : '0'};
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	transition: opacity 0.3s;
	border-radius: 30px;
`
const ColumnsIcon = styled(Columns)`
	display: none;
	width: 7px;
	position: relative;
	z-index: 2;
`
const SquaresIcon = styled(Squares)`
	display: none;
	width: 7px;
	position: relative;
	z-index: 2;
	${mobile}{
		display: block
	}
`
const Text = styled.div`
	display: block;
	position: relative;
	z-index: 2;
	${mobile}{
		display: none;
	}
`

LayoutToggle.propTypes = {
	className: PropTypes.string
}

AnimatedButton.propTypes = {
	active: PropTypes.bool,
	children: PropTypes.node,
	onClick: PropTypes.func,
	className: PropTypes.string,
}


export default LayoutToggle